import React from 'react';
import Layout from '../../../components/Layout';
import Seo from '../../../components/SEO';
import SectionIntro from '../../../components/SectionIntro';
import peopleJit from '../../../images/product/people-jit.svg';
import awsTrustleLogo from '../../../images/product/aws-trustle-logo.svg';
import brushLine from '../../../images/product/brush-line.svg';
import {getImage, StaticImage} from 'gatsby-plugin-image';
import {Link, useStaticQuery, graphql} from 'gatsby';
import Button from '../../../components/Button';
import iconSolve01 from '../../../images/product/icon-solve-01.svg';
import iconSolve02 from '../../../images/product/icon-solve-02.svg';
import iconSolve04 from '../../../images/product/icon-solve-04.svg';
import iconSolve05 from '../../../images/product/icon-solve-05.svg';
import lineLarge from '../../../images/homepage/icon-line-xl.svg';
// Background images
import {convertToBgImage} from 'gbimage-bridge';
import BackgroundImage from 'gatsby-background-image';

const sectionTitleClass = `mb-6 text-3xl font-bold md:text-4xl leading-[2.75rem] md:leading-[3rem] text-blue`;
const sectionTextClass = `mb-6 text-lg text-left xl:leading-loose`;

const Itdr = () => {
  const dataImages = useStaticQuery(graphql`
    query {
      placeholderImage: file(relativePath: {eq: "bg/bg-section-header.jpg"}) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED)
        }
      }
      placeholderImage02: file(relativePath: {eq: "bg/bg-section-main-02.jpg"}) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED)
        }
      }
      placeholderImage03: file(relativePath: {eq: "bg/bg-section-main-03.jpg"}) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED)
        }
      }
    }
  `);
  const {placeholderImage, placeholderImage02, placeholderImage03} = dataImages;
  const image = getImage(placeholderImage);
  const bgImage = convertToBgImage(image);
  const image02 = getImage(placeholderImage02);
  const bgImage02 = convertToBgImage(image02);
  const image03 = getImage(placeholderImage03);
  const bgImage03 = convertToBgImage(image03);

  return (
    <Layout
      seo={
        <Seo
          title="Identity Threat Detection and Response (ITDR) | Trustle"
          description="Keeping track of who has access across all your cloud-based services far exceeds human capacity."
        />
      }
    >
      <BackgroundImage
        Tag="section"
        // Spread bgImage into BackgroundImage:
        {...bgImage}
        preserveStackingContext
        className="bg-cover"
      >
        <section className="pt-16 pb-8">
          <div className="max-w-5xl px-5 pt-0 mx-auto text-center text-white">
            <SectionIntro
              hero="Product"
              title="Identity Threat Detection and Response (ITDR)"
              color="white"
              description=""
              isPageHeader
            />
          </div>
          <div className="mb-8 md:mb-16 text-sm w-[250px] mx-auto font-bold text-center">
            <Link to="/demo">
              <Button label="Schedule a Demo" variant="secondary" />
            </Link>
          </div>
        </section>
      </BackgroundImage>

      <BackgroundImage
        Tag="section"
        // Spread bgImage into BackgroundImage:
        {...bgImage02}
        preserveStackingContext
        className="bg-cover"
      >
        <section>
          <div className="pt-0 mx-auto mb-20 text-center text-white max-w-[1920px]">
            <div className="flex flex-col justify-between xl:flex-row ">
              <div className="xl:w-1/2">
                {' '}
                <div className="">
                  <StaticImage
                    src="../../../images/product/product-section-person-state.jpg"
                    alt="trustle app"
                    className="w-full mb-8"
                  />
                </div>
              </div>
              <div className="flex flex-col items-start px-5 pt-12 xl:w-1/2 2xl:pl-8 text-blue">
                <div className="mx-auto xl:max-w-xl">
                  <div className={`${sectionTitleClass} text-left`}>Do you know who has access to what?</div>
                  <p className={sectionTextClass}>
                    Keeping track of who has access across all your cloud-based services far exceeds human capacity.
                  </p>
                  <div className="mb-4 text-xl font-bold text-left md:text-2xl">Security teams are overwhelmed, </div>
                  <p className={sectionTextClass}>
                    approving access without context. Increasing the risk of approving unusual or suspicious activity.
                  </p>
                  <div className="mb-4 text-xl font-bold text-left md:text-2xl">
                    Approval workflows for your full-time employees is already a struggle,{' '}
                  </div>
                  <p className={sectionTextClass}>
                    but it becomes even more burdensome when you include contractors, Customer-based accounts, and
                    System-based accounts.
                  </p>
                  <div className="text-xl font-bold text-left md:text-2xl">
                    That’s where{' '}
                    <span className="relative">
                      {' '}
                      Trustle comes in.
                      <img src={lineLarge} alt="blue line" className="absolute right-0 " />
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section>
          <div className="px-5 pt-0 pb-20 text-center text-white max-w-8xl">
            <div className={`${sectionTitleClass} mb-20`}>How does Trustle solve this...</div>
            <div className="flex flex-col items-center xl:items-baseline xl: xl:flex-row max-w-[1360px] mx-auto justify-between">
              <div className="flex flex-col md:w-[320px] text-blue items-center mb-8 xl:mb-0">
                <div className="mb-12">
                  <img src={iconSolve01} alt="icon solution" />
                </div>
                <div className="mb-8 text-xl font-bold text-left md:text-2xl">Advanced APIs</div>
                <p className="text-lg md:max-w-[315px] text-left">
                  Trustle utilizes advanced native APIs to seamlessly gather crucial data, including account
                  information, permissions, resources, and usage details, from all your cloud and SaaS system.
                </p>
              </div>
              <div className="flex flex-col md:w-[320px] text-blue items-center mb-8 xl:mb-0">
                <div className="mb-12">
                  <img src={iconSolve02} alt="icon solution" />
                </div>
                <div className="mb-8 text-xl font-bold text-left md:text-2xl">Streamline Workflows</div>
                <p className="text-lg  md:max-w-[315px] text-left">
                  This data is then leveraged to optimize and streamline your access request workflows. With the aid of
                  Trustle's powerful machine learning technology.
                </p>
              </div>
              <div className="flex flex-col md:w-[320px] text-blue items-center mb-8 xl:mb-0">
                <div className="mb-12">
                  <img src={iconSolve04} alt="icon solution" />
                </div>
                <div className="mb-8 text-xl font-bold text-left md:text-2xl">Comprehensive Visibility</div>
                <p className="text-lg  md:max-w-[315px] text-left">
                  Make informed decisions based on contextual insights. and you gain comprehensive end-to-end visibility
                  and control over each system.
                </p>
              </div>
              <div className="flex flex-col md:w-[320px] text-blue items-center mb-8 xl:mb-0">
                <div className="mb-12">
                  <img src={iconSolve05} alt="icon solution" />
                </div>
                <div className="mb-8 text-xl font-bold text-left md:text-2xl">Automated Provisioning</div>
                <p className="text-lg  md:max-w-[315px] text-left">
                  Facilitate compliant and automated access provisioning for the appropriate users, resources, and
                  duration..
                </p>
              </div>
            </div>
          </div>
        </section>
      </BackgroundImage>
      <section className="mb-20 ">
        <div className="xl:max-w-[620px] xl:top-16 2xl:max-w-[770px] 2xl:top-12 3xl:max-w-[950px] 3xl:top-16 relative w-full float-right">
          <img src={peopleJit} alt="pleople jit the fan" className="hidden xl:absolute xl:flex" />
        </div>
        <BackgroundImage
          Tag="section"
          // Spread bgImage into BackgroundImage:
          {...bgImage}
          preserveStackingContext
          className="bg-cover"
        >
          <div className="px-5 pb-4 ">
            <div className="flex flex-col mx-auto text-white max-w-7xl">
              <div
                className={`mb-6 text-3xl font-bold md:text-4xl leading-[2.75rem] md:leading-[3rem] pt-8 text-white`}
              >
                Don’t wait for the JIT to hit the Fan!
              </div>
              <div className="flex flex-col xl:flex-row">
                <p className={`${sectionTextClass} text-white xl:max-w-[652px]`}>
                  According to the Gartner report: Reduce Risk Through a Just-in-Time Approach to Privileged Access
                  Management, “By the year 2025, 75% of cyber insurance providers will mandate the use of JIT PAM
                  principles.” In addition to the peace of mind gained by properly managing privileged access in your
                  organization, get ahead of the curve and let Trustle help you manage privileged access in your
                  organization today!
                </p>
                <img src={peopleJit} alt="pleople jit the fan" className="flex w-full xl:hidden" />
              </div>
            </div>
          </div>
        </BackgroundImage>
        <div className="h-12 bg-[#F3FAFF]"></div>
      </section>
      <BackgroundImage
        Tag="section"
        // Spread bgImage into BackgroundImage:
        {...bgImage03}
        preserveStackingContext
        className="bg-cover"
      >
        <section>
          <div className="px-5 pt-0 mx-auto mb-20 text-center text-white max-w-[1360px]">
            <div className={`${sectionTitleClass} mb-20`}>Trustle’s ITDR features</div>

            <div className="flex flex-col justify-between lg:flex-row">
              <div className="mb-8 lg:mb-0 max-w-[550px]">
                <StaticImage
                  src="../../../images/product/product-features-01.png"
                  alt="trustle app"
                  className="w-full"
                />
              </div>
              <div className="max-w-[658px] flex flex-col items-start text-blue">
                <div className="mb-8 text-left">
                  <div className="mb-4 text-xl font-bold md:text-2xl">Proactive Risk Management</div>
                  <div className={sectionTextClass}>
                    <b>
                      With Trustle, you can take a distributed, proactive approach to risk management and stay ahead of
                      potential threats to your cloud environment.
                    </b>
                  </div>
                  <p className={sectionTextClass}>
                    By identifying and addressing risks and vulnerabilities, you can reduce the likelihood of
                    intentional and accidental exposures, ensuring that your organization remains secure.
                  </p>
                </div>
              </div>
            </div>

            <div className="flex flex-col justify-between lg:flex-row">
              <div className="mb-8 lg:mb-0 max-w-[550px]">
                <StaticImage
                  src="../../../images/product/product-features-02.png"
                  alt="trustle app"
                  className="w-full"
                />
              </div>
              <div className="max-w-[658px] flex flex-col items-start text-blue">
                <div className="mb-8 text-left">
                  <div className="mb-4 text-xl font-bold md:text-2xl">Automated Risk Assessment</div>

                  <p className={sectionTextClass}>
                    Trustle helps you approach risk proactively, providing compliance and access history reports to keep
                    key stakeholders informed. For management and systems owners, these reports facilitate
                    policy-related discussions and changes, making it easier to stay on top of security.  For auditors,
                    these reports provide an automated method of generating evidence of regulatory compliance. <br></br>
                    Our automated risk assessment provides a holistic view of each Saas and Cloud environment,
                    identifying risks, misconfigurations, and vulnerabilities, and our machine learning technology
                    detects anomalous and potentially threatening access requests, in addition to well-known risks.{' '}
                    <br></br>
                  </p>
                  <p className={sectionTextClass}>
                    <b>This helps you stay one step ahead of potential breaches.</b>
                  </p>
                </div>
              </div>
            </div>

            <div className="flex flex-col justify-between lg:flex-row">
              <div className="mb-8 lg:mb-0 max-w-[550px]">
                <StaticImage
                  src="../../../images/product/product-features-03.png"
                  alt="trustle app"
                  className="w-full"
                />
              </div>
              <div className="max-w-[658px] flex flex-col items-start text-blue">
                <div className="mb-8 text-left">
                  <div className="mb-4 text-xl font-bold md:text-2xl">Flexible and Convenient Risk Remediation</div>
                  <p className={sectionTextClass}>
                    Trustle also offers flexible and convenient methods for risk remediation. Once you understand your
                    security posture and attack surface, Trustle provides multiple options for automatic and interactive
                    account and access-related mitigation.<br></br>
                  </p>
                  <p className={sectionTextClass}>
                    <b>Trustle is quick and easy to set up, taking only minutes instead of weeks.</b>
                  </p>
                  <div className="text-xl font-bold text-left md:text-2xl">
                    We value efficiency, functionality, and security, so you don't have to compromise.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="pb-20 mx-5">
          <div className="flex flex-col md:flex-row w-full lg:max-w-[85rem] lg:mx-auto mb-8 bg-white text-blue rounded-[30px] p-7 md:p-10 filter drop-shadow-2xl items-center">
            <div className="relative flex flex-col justify-between h-full">
              <img src={awsTrustleLogo} alt="Aws & Trustle" className="max-w-[155px]" />
            </div>
            <div className="mt-4 md:ml-8 md:mt-0">
              <div className="font-bold -top-20 sm:-top-28 left-48 sm:left-52 lg:static lg:w-full">
                <h3 className="pb-2 text-3xl md:text-2xl">
                  For a limited time, we're offering a{' '}
                  <span className="relative">
                    Free
                    <img
                      src={brushLine}
                      alt="underline brush style"
                      className="absolute left-0 inline-block top-9 md:top-8"
                    />
                  </span>{' '}
                  AWS assessment
                </h3>
              </div>
              <div className="pb-0 my-4 md:my-10 lg:mt-6 text-md md:text-lg ">
                {' '}
                Including a full-feature test drive of Trustle’s Risk Assessment and Remediation capabilities. Get
                started in minutes and see just how easy it is to identify and remediate access issues within your
                organization.
              </div>
              <a href="https://trustle.trustle.io/mkt/signup" target="_blank" rel="noreferrer">
                <Button label="Try Trustle Today!" variant="primary" />
              </a>
            </div>
          </div>
        </section>
      </BackgroundImage>
    </Layout>
  );
};
export default Itdr;
